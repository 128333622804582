import { Container } from "./styles";
import ScrollAnimation from "react-animate-on-scroll";


export function Experience() {
  return (
    <Container id="about">
      <div className="about-text">
        <ScrollAnimation animateIn="fadeInLeft">
          <h2>Experience</h2>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.1 * 1000}>
          <h3 className="title">
            Nahr Group | Backend Developer
          </h3>
          <p>
          Basically writing the backend part of the programs and deploying the program to the server. Our currently completed project is the moliyaviytahlil.uz site.         
          </p>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeInLeft" delay={0.1 * 1000}>
          <h3 className="title">
            Dynamic Soft | Backend Developer
          </h3>
          <p>
            Writing CRM, ERM and landing pages, experience with payment systems. I workign on the backend of cash registers. Experience with PostgreSQL, MongoDB, NestJS nginx and other web servises.
          </p>
        </ScrollAnimation>

      </div>

    </Container>
  )
}

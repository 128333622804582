import Particles from "@tsparticles/react";
import { Container } from "./styles";
import { Hero } from "../Hero/Hero";
import { About } from "../About/About";
import { Experience } from "../Experience/experience";
import { Contact } from "../Contact/Contact";
import { Project } from "../Project/Project";
import { data1 } from "./data";
export function Main() {
  return (
    <>
      <Container>
        <Particles id="tsparticles" options={{ data1 }} />

        <Hero />
        <About />
        <Experience/>
        <Project />
        <Contact />
      </Container>
    </>
  );
}
